<div class="card">

    <div>
        {{field.label}}
    </div>

    <div>
        <button (click)="downloadPdf(field.customField7)" color="warn" mat-icon-button type="button">
            <mat-icon>download

            </mat-icon>

        </button>
    </div>

</div>